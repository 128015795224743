<template>
    <v-container fluid>
        <v-alert
        dense
        outlined
        type="error"
        v-if="Object.keys(validation_errors).length > 0"
        >
            <ul>
                <li v-for="(errors, field) in validation_errors" :key="field">
                    {{ field }}
                    <ul>
                        <li v-for="(error, sl) in errors" :key="sl">
                            {{ error }}
                        </li>
                    </ul>
                </li>
            </ul>
        </v-alert>
        <v-form class="custom-form" ref="loanForm" @submit.prevent="saveLoan">
            <v-row dense>
                <v-col 
                    cols="12" 
                    md="4"
                    style="height: 272px"
                >
                    <v-combobox
                        label="Employee"
                        dense
                        outlined
                        hide-details
                        :items="employees"
                        :loading="employee_loading"
                        v-model="selectedEmployee"
                        item-text="display_name"
                        item-value="id"
                        class="mb-1"
                        @focus="getEmployees"
                    ></v-combobox>
                    <v-card :dark="selectedEmployee ? true : false" :color="selectedEmployee ? 'primary' : '#ECEFF1'" :elevation="1">
                        <v-card-text class="text-center pa-2">
                            <v-avatar
                                color="deep-purple darken-4"
                                size="120"
                                class="mb-4"
                            >
                                <v-img v-if="selectedEmployee" :src="$store.state.host+selectedEmployee.image"></v-img>
                                <v-img v-else src=""></v-img>
                            </v-avatar>
                            <h2 class="caption mb-0">
                                {{ selectedEmployee ? selectedEmployee.emp_id : 'Employee ID' }}
                            </h2>
                            <h1 class="subtitle-2">{{ selectedEmployee ? selectedEmployee.name : 'Employee name' }}</h1>
                            <p class="caption mb-0">
                                {{ selectedEmployee ? selectedEmployee.designation_name : 'Designation' }}, 
                                {{ selectedEmployee ? selectedEmployee.department_name : 'Department' }}
                            </p>
                            <p class="caption mb-0">
                                {{ selectedEmployee ? selectedEmployee.phone : 'Phone' }}
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col 
                    cols="12" 
                    md="4"
                    style="height: 272px"
                >
                    <v-combobox
                        label="Loan"
                        dense
                        outlined
                        hide-details
                        :items="loans"
                        :loading="loan_loading"
                        v-model="selectedLoan"
                        item-text="display_text"
                        item-value="id"
                        class="mb-1"
                    ></v-combobox>
                    <v-card color='#ECEFF1' :elevation="1">
                        <v-card-text class="pa-2">
                            <v-simple-table dense style="text-align: left;padding: 5px;">
                                <tr>
                                    <th>Tr. ID</th>
                                    <th>{{selectedLoan ? selectedLoan.code : ''}}</th>
                                </tr>
                                <tr>
                                    <th>Entry Date</th>
                                    <th>{{selectedLoan ? selectedLoan.entry_date : ''}}</th>
                                </tr>
                                <tr>
                                    <th>Start From</th>
                                    <th>{{selectedLoan ? selectedLoan.start_from : '' | dateFormat('YYYY-MM')}}</th>
                                </tr>
                                <tr>
                                    <th>Loan Amount</th>
                                    <th>{{selectedLoan ? selectedLoan.loan_amount : ''}}</th>
                                </tr>
                                <tr>
                                    <th>Due Amount</th>
                                    <th>{{selectedLoan ? selectedLoan.due_amount : ''}}</th>
                                </tr>
                                <tr>
                                    <th>Installment</th>
                                    <th>{{selectedLoan ? selectedLoan.total_installment : ''}}</th>
                                </tr>
                                <tr>
                                    <th>Due Installment</th>
                                    <th>{{selectedLoan ? selectedLoan.due_installment : ''}}</th>
                                </tr>
                                <tr>
                                    <th>Installment Amount</th>
                                    <th>{{selectedLoan ? selectedLoan.installment_amount : ''}}</th>
                                </tr>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4">
                    <v-card height="100%" :elevation="1">
                        <v-toolbar color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Installment Information</v-toolbar-title>
                        </v-toolbar>

                        <v-card-text>
                            <v-row>
                                <v-col cols="5" class="py-1 caption">Inst. Tr.</v-col>
                                <v-col cols="7" class="py-0">
                                    <v-text-field
                                        v-model="installment.code"
                                        dense
                                        outlined
                                        hide-details
                                        autocomplete="off"
                                        readonly
                                        :rules="[() => !!installment.code || '']"
                                        :error-messages="validation_errors.code"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5" class="py-1 caption">Month</v-col>
                                <v-col cols="7" class="py-0">
                                    <v-menu 
                                        max-width="290"
                                        v-model="menu"
                                        :close-on-content-click="false"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                dense
                                                readonly
                                                outlined
                                                hide-details
                                                v-on="on"
                                                v-model="installment.month"
                                                :rules="[() => !!installment.month || '']"
                                                :error-messages="validation_errors.month"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            no-title
                                            type="month"
                                            v-model="installment.month"
                                            @input="menu = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5" class="py-1 pr-1 caption">Inst. Amount</v-col>
                                <v-col cols="7" class="py-0">
                                    <v-text-field
                                        v-model.number="installment.amount"
                                        dense
                                        outlined
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5" class="py-1 caption">Remark</v-col>
                                <v-col cols="7" class="py-0">
                                    <v-textarea
                                        rows="2"
                                        dense
                                        outlined
                                        v-model="installment.remark"
                                        hide-details
                                        :rules="[v => (v || '' ).length >= 10 || 'Remark at least 10 characters']"
                                        :error-messages="validation_errors.remark"
                                    >
                                    </v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12" class="text-right">
                                    <v-btn height="30px" dark color="light-blue darken-2 mr-1" :loading="loading" :elevation="1" type="submit">Update</v-btn>
                                    <v-btn height="30px" dark color="deep-orange" :elevation="1" @click="resetForm">Cancel</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-form>
        <v-row v-if="installments.length > 0">
            <v-col cols="12" class="py-0">
                <v-toolbar dense color="white" :elevation="1" height="30px">
                    <v-toolbar-title class="subtitle-2">Loan Installment Record</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-btn
                        color="primary"
                        class="white--text"
                        small
                        dense
                        height="25"
                        @click="print"
                    >
                        <v-icon left small dense> mdi-printer </v-icon>
                        Print
                    </v-btn>
                </v-toolbar>
            </v-col>
            <v-col cols="12" md="12" class="py-1" id="reportContent">
                <v-simple-table dense class="record_table">
                    <thead>    
                        <tr class="record_heading_tr">
                            <th>SL.</th>
                            <th>Inst. Tr. ID</th>
                            <th>Month</th>
                            <th>Amount</th>
                            <th>Remark</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody class="text-center">
                        <tr v-for="(record, sl) in installments" :key="sl">
                            <td>{{++sl}}</td>
                            <td>{{record.code}}</td>
                            <td>{{record.month | dateFormat('YYYY-MM')}}</td>
                            <td>{{record.amount}}</td>
                            <td>{{record.remark}}</td>
                            <td>{{record.status_text}}</td>
                            <td>
                                <v-tooltip bottom v-if="$store.state['user'].role != 'User' && record.status == 'p'">
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            small
                                            @click="editLoan(record)"
                                            color="primary"
                                            v-on="on"
                                            >mdi-circle-edit-outline</v-icon
                                        >
                                    </template>
                                    <span>Edit</span>
                                </v-tooltip>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import utility from '../../mixins/utility.mixin';
export default {
    mixins: [utility],
    data(){
        return{
            employees       : [],
            selectedEmployee: null,
            loans           : [],
            selectedLoan    : null,
            installment     : {
                id    : null,
                code  : '',
                month : '',
                amount: '',
                remark: '',
            },
            installments     : [],
            loading          : false,
            menu             : false,
            validation_errors: {},
            employee_loading : false,
            loan_loading     : false,
        }
    },
    watch: {
        selectedEmployee(emp){
            this.getLoans();
            if(!emp || emp.id == undefined){
                return;
            }
        },
        selectedLoan(loan){
            this.getInstallments();
            if(!loan || loan.id == undefined){
                return;
            }
        },
    },
    created () {
    },
    methods: {

        async getLoans(){
            this.loan_loading = true;
            this.selectedLoan = null;
            this.loans = [];
            if(this.selectedEmployee && this.selectedEmployee.id != undefined){
                this.loans = await this.$store.dispatch('loan/getLoans',{
                    apiParams: {
                        employee_id: this.selectedEmployee.id
                    },
                    options: {
                        returnData: true,
                    }
                });
            }
            this.loan_loading = false;
        },
        async getInstallments(){
            this.installments = [];
            if(this.selectedLoan && this.selectedLoan.id != undefined){
                this.installments = await this.$store.dispatch('loan/getLoanInstallments',{
                    apiParams: {
                        loan_id: this.selectedLoan.id
                    },
                    options: {
                        returnData: true,
                    }
                });
            }
        },
        async getEmployees(){
            this.employee_loading = true;
            this.employees = await this.$store.dispatch('employee/getEmployees', {
                apiParams: {},
                options: {
                    returnData: true
                }
            });
            this.employee_loading = false;
        },
        async saveLoan(){
            if(!this.$refs.loanForm.validate()){
                this.$store.dispatch('snackbar/error', 'Fill red marked fields');
                return;
            }

            this.loading = true;

            let resObj = await this.$store.dispatch('loan/updateLoanInstallment', this.installment);

            if(resObj.hasOwnProperty("validation_errors")){
                this.validation_errors = resObj.validation_errors;
            }
            this.loading = false;
            if(resObj.success) {
                this.resetForm();
            }
        },
        resetForm(){
            this.selectedEmployee = null;
            this.validation_errors = {};

            this.installment = {
                id: null,
                code: '',
                month: '',
                amount: '',
                remark: '',
            };

            this.$refs.loanForm.resetValidation();
        },
        editLoan(loan){
            this.installment = {
                id: loan.id,
                code: loan.code,
                month: loan.month.substr(0,7),
                amount: loan.amount,
                remark: loan.remark,
            }
        },
        async print(){
            let title = 'Installment Record of Loan: ';
            if(this.selectedLoan && this.selectedLoan.id != undefined){
                title += this.selectedLoan.code;
            }

            let company_profile = this.$store.getters['company/company'];

            // Get HTML to print from element
            const prtHtml = document.getElementById('reportContent').innerHTML;

            // Get all stylesheets HTML
            let stylesHtml = '';
            for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
                stylesHtml += node.outerHTML;
            }

            // Open the print window
            // const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
            const WinPrint = window.open('', 'PRINT', `height=${screen.height}, width=${screen.width}`);
            WinPrint.document.write(`<!DOCTYPE html>
            <html>
            <head>
                ${stylesHtml}
            </head>
            <body>
                <div data-app="true" class="v-application v-application--is-ltr theme--light" id="app">
                    <div class="container container--fluid">
                        <div class="row row--dense">
                            <div class="col-2 px-2">
                                <img style="width: 100%; height: 50px;" src="${this.$store.state.host+company_profile.logo}">
                            </div>
                            <div class="col-8 px-2 text-center">
                                <span class="font-weight-black">
                                    ${company_profile.name}
                                </span>
                                <br>
                                <span>${company_profile.address}</span>
                                <br>
                                <span>
                                    Email:${company_profile.email}, Contact:${company_profile.phone}
                                </span>
                            </div>
                            <div class="col-2 px-2">
                                <img style="width: 100%; height: 50px;" src="${this.$store.state.host+'aritech.png'}">
                            </div>
                            <div class="col-12 px-2 text-center">
                                <p class="font-weight-black custom_header">
                                    ${title}
                                </p>
                            </div>
                            <div class="col col-12 px-2">
                                ${prtHtml}
                            </div>
                        </div>
                    </div>
                </div>
            </body>
            </html>`);

            WinPrint.document.head.innerHTML += `
                <style>
                    .v-data-table__wrapper{
                        overflow: unset;
                    }
                    
                    .record_heading_tr th{
                        text-align: center !important;
                    }
                    .custom_header{
                        background-color: #0000001f;
                        border-radius: 4px;
                        margin-bottom: 5px !important;
                    }
                    tr:hover{
                        background: unset !important;
                    }
                    .record_heading_tr:hover{
                        background: #4caf7773 !important;
                    }
                    @media print {
                        table.record_table { page-break-after:auto }
                        table.record_table tr    { page-break-inside:avoid; page-break-after:auto }
                        table.record_table td    { page-break-inside:avoid; page-break-after:auto }
                        table.record_table thead { display:table-header-group }
                        table.record_table tfoot { display:table-footer-group }
                    }
                </style>
            `;

            let rows = WinPrint.document.querySelectorAll('.record_table tr');
            rows.forEach(row => {
                row.lastChild.remove();
            })

            WinPrint.focus();
            WinPrint.document.close();
            await new Promise(resolve => setTimeout(resolve, 2000));
            WinPrint.print();
            WinPrint.close();
        }
    },
}
</script>

<style scoped>
    .record_table{
        margin-bottom: 5px;
    }
    .record_table table, .record_table th, .record_table td {
        border: 1px solid #dee2e6;
        border-collapse: collapse;
        padding: 0 !important;
        
    }
    .record_table th, .record_table td {
        padding: 2px !important;
        font-size: 11px !important;
        text-align: center !important;
        height: 0 !important;
    }
    .record_heading_tr{
        background: #4caf7773;
    }
</style>